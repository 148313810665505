export enum WhlLocationSearchType {
  None = -1,
  /** Spielstätte */
  EventLocation = 0,
  AddressPoiGroup = 1,
  ZipCode = 2,
  Coordinates = 3,
  Region = 4,
  /** Ort */
  Location = 5,
}
